<template>
    <div class="dropdown language-control" v-on-click-away="hideLanguage" v-if="languageEnabled">
        <a href="javascript:void(0)"  @click="togglePage()" class="button light-button dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="d-inline-block language-flag"><img :src="selectedLanguage.image" alt="flag" class="lang-flag" /></div>
            <span>{{ selectedLanguage.name }} <small v-if="selectedLanguage.extra.length>0">{{selectedLanguage.extra}}</small></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" :class="{ 'show' : listOpen }">
            <ul>
                <li v-for="lang in allLanguages">
                    <a href="javascript:void(0)" @click="changeLanguage(lang.code)">
                        <div class="d-inline-block language-flag"><img :src="lang.image" alt="language" class="lang-flag" /></div>
                        {{ lang.name }} <small v-if="lang.extra.length>0">{{lang.extra}}</small>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import appConfig from '../../config/app';
    import { mapState, mapActions } from 'vuex';
    import { CHANGE_LANGUAGE } from '../../constants/action-type';
    import {loadLanguageAsync} from '../../app/i18n'
    import {EventBus} from "../../extra/event-bus";
    import {SWITCHED_TO_ANOTHER_ACCOUNT, SWITCHED_TO_ANOTHER_LANGUAGE} from "../../constants/events";


    export default {
        data() {
            return {
                languageEnabled : true,
                listOpen : false
            }
        },
        methods : {
            ...mapActions([
                CHANGE_LANGUAGE
            ]),

            changeLanguage(code) {
                loadLanguageAsync(code).then((lang) => this[CHANGE_LANGUAGE](lang));
                this.togglePage();
            },

            togglePage() {
                this.listOpen = !this.listOpen;
            },
            hideLanguage () {
                this.listOpen = false;

            }
        },

        computed : {
            ...mapState(['language','user']),

            selectedLanguage() {

                EventBus.$emit(SWITCHED_TO_ANOTHER_LANGUAGE, this.language);
                return _.find(appConfig.lang,{
                    code : this.language
                });

            },

            allLanguages() {
                let list =  appConfig.lang.sort((a,b) => {
                    return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
                });

                list = list.filter( lang => lang.code !== 'en');

                list.unshift(
                    _.find(appConfig.lang,{
                        code : 'en'
                    })
                )

                return  list.filter( lang => lang.code !==  this.selectedLanguage.code );
            }
        },

        mounted () {
            // prevent click outside event with popupItem.
            this.popupItem = this.$el;
        },
    }
</script>

<style scoped>
.language-control .dropdown-menu ul {
    list-style: none;
}
</style>