<template>
    <div class="header__conversation notification-list" v-if="hasOrganizeCandidatePermission" v-on-click-away="hideConversation">

        <router-link v-if="!showPopUp" class="button light-button" :to="moveToConversationPage">
            <i class="eicon e-chat"></i>
            <span class="notification-number" v-if="unseenMessage">{{ unseenMessage }}</span>
        </router-link>

        <template v-else>

            <button @click="toggleConversation()" class="button light-button dropdown-toggle">
                <i class="eicon e-chat"></i>
            </button>

            <div class="dropdown-menu dropdown-menu-right show" v-if="showConversation">
                <div class="notification-head">
                    <h4>{{ $t("Messages")}}</h4>
                </div>

                <template v-if="notifyStatus === NO_ACTIVE_JOB_FOUND && this.company">
                    <div class="notification-body">
                        <h6 class="empty-message text-center mt-0 mb-0">
                            {{ $t("No conversations found.") }}
                        </h6>
                        <p>{{ $t("You've no active jobs. Create jobs, invite candidates then start conversation with them.") }}</p>
                    </div>
                    <div class="notification-footer text-center">
                        <router-link class="semi-button semi-button-info" :to="{name: 'job.create'}">
                            {{ $t("Create a Job Post") }}
                        </router-link>
                    </div>
                </template>

                <template v-else-if="notifyStatus === NO_APPLICANT_FOUND && this.company">
                    <div class="notification-body">
                        <h6 class="empty-message text-center mt-0 mb-0">
                            {{ $t("No conversations found.") }}
                        </h6>
                        <p>{{ $t('You have active job(s) but none has applied yet. You can invite candidates in the following job then you can start conversation with them.') }}</p>
                        <p class="p-1 mt-3 font-weight-bold mb-0">{{ jobTitle }}</p>
                    </div>
                    <div class="notification-footer text-center">
                        <router-link v-if="latestJobId" class="semi-button semi-button-info" :to="{name: 'job.candidates', params: {jobId: latestJobId}, query: {invite: true}}">
                            {{ $t("Invite Candidates") }}
                        </router-link>
                    </div>
                </template>

                <template v-else-if="notifyStatus === NO_MESSAGE_FOUND  && this.company">
                    <div class="notification-body">
                        <h6 class="empty-message text-center mt-0 mb-0">
                            {{ $t("No conversations found.") }}
                        </h6>
                        <p>{{ $t('Please review your job applications and then start conversation with candidates.') }}</p>
                    </div>
                    <div class="notification-footer text-center">
                        <router-link class="semi-button semi-button-info" :to="{name: 'company.candidates'}">
                            {{ $t("Show Me Candidates")}}
                        </router-link>
                    </div>
                </template>

                <template v-else-if="notifyStatus === NO_MESSAGE_FOUND  && !this.company">
                    <div class="notification-body">
                        <h6 class="empty-message text-center mt-0 mb-0">
                            {{ $t("No conversations found.") }}
                        </h6>
                        <p>{{ $t("You'll be notified if there are any message from any organization.") }}</p>
                    </div>
                    <div class="notification-footer text-center">
                        <a class="semi-button semi-button-info" href="javascript:void(0);" @click="toggleConversation()">
                            {{ $t("Ok")}}
                        </a>
                    </div>
                </template>

                <template v-else-if="!this.company">
                    <div class="notification-body">
                        <h6 class="empty-message text-center mt-0 mb-0">
                            {{ $t("No conversations found.") }}
                        </h6>
                        <p>{{ $t("You'll be notified if there are any message from any organization.") }}</p>
                    </div>
                    <div class="notification-footer text-center">
                        <a class="semi-button semi-button-info" href="javascript:void(0);" @click="toggleConversation()">
                            {{ $t("Ok")}}
                        </a>
                    </div>
                </template>

            </div>
        </template>

    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {canOrganizeCandidate} from "../../config/permission";
import {LOAD_MESSAGE_NOTIFICATION} from "../../constants/action-type";

export default {
    data() {
        return {
            showPopUp: false,
            showConversation: false,
            unseenMessage: 0,
            notifyStatus: '',
            latestJobId: null,
            jobTitle: '',
            NO_ACTIVE_JOB_FOUND: 1,
            NO_APPLICANT_FOUND: 2,
            NO_MESSAGE_FOUND: 3,
        }
    },

    computed: {
        ...mapState(['company', 'messageNotification']),

        moveToConversationPage() {
            if (this.company) {
                return `/company/conversations`;
            }
            return `/candidate/conversations`;
        },

        hasOrganizeCandidatePermission() {
            if (this.company) {
                return canOrganizeCandidate();
            }
            return true;
        }
    },

    methods: {
        ...mapActions([LOAD_MESSAGE_NOTIFICATION]),

        async loadMessageNotification() {
            this[LOAD_MESSAGE_NOTIFICATION]();
        },

        showMessageNotificationPopUp() {
            let {unseenMessage, notifyStatus, latestJobId, jobTitle} = this.messageNotification;

            this.unseenMessage = unseenMessage;

            if ([this.NO_ACTIVE_JOB_FOUND, this.NO_APPLICANT_FOUND, this.NO_MESSAGE_FOUND].includes(notifyStatus)) {
                this.notifyStatus = notifyStatus;
                this.showPopUp = true;
                this.latestJobId = latestJobId;
                this.jobTitle = jobTitle;
            }
        },

        toggleConversation() {
            this.showConversation = !this.showConversation;
        },

        hideConversation() {
            this.showConversation = false;
        },
    },

    async mounted() {
        await this.loadMessageNotification();

        this.showMessageNotificationPopUp();
    },

    watch: {
        messageNotification: {
            handler(newValue, oldValue) {
                this.showMessageNotificationPopUp();
            },
            deep: true
        }
    }
}
</script>


<style scoped>

    .empty-message {
        padding: 15px 26.5px;
    }

    .notification-body {
        padding: 0 15px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
    }

    .header__conversation {
        position: relative;
    }
    .header__conversation .dropdown-menu-right {
        top: calc(100% + 25px) !important;
    }

</style>
