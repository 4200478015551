<template>
    <div class="content__header__wrap">
        <header class="content-area__header d-flex justify-content-between">
            <ReleaseNotification></ReleaseNotification>
            <div class="header-content-left">
                <button class="toggler-button" @click.prevent="toggleSidebar" :class="{'toggler-active' : showSidebar}">
                    <img src="/img/ej-menu-icon.svg" alt="">
                </button>
                <a v-if="company" :href="company.url" target="_blank" class="button info-button">
                    <span>{{ $t("View company page") }}</span>
                </a>
            </div>
            <div class="button-group d-flex">
                <language-switcher></language-switcher>
                <conversations></conversations>
                <notifications></notifications>
                <header-user-panel></header-user-panel>
            </div>
        </header>
    </div>
</template>
<script>
    import LanguageSwitcher from "../dropdown/LanguageSwitcher";
    import Conversations from "../dropdown/Conversations";
    import Notifications from "../dropdown/Notifications";
    import HeaderUserPanel from "../dropdown/HeaderUserPanel";
    import {mapActions, mapState} from 'vuex';
    import {TOGGLE_SIDEBAR} from "../../constants/action-type";
    import ReleaseNotification from "../common/ReleaseNotification";

    export default {
        components : {
            LanguageSwitcher,
            Conversations,
            Notifications,
            HeaderUserPanel,
            ReleaseNotification
        },
        computed : {
            ...mapState(['company', 'showSidebar'])
        },
        methods: {
            ...mapActions([TOGGLE_SIDEBAR]),
            toggleSidebar() {
                this[TOGGLE_SIDEBAR]();
            }
        }
    }
</script>

<style scoped>
@media all and (max-width: 991px) {
    .content__header__wrap {
        height: 70px;
    }
}
@media all and (max-width: 767px) {
    .content__header__wrap {
        height: 55px;
    }
}
</style>
