<template>
    <div class="dropdown profile-control" v-on-click-away="hide">
        <button v-if="selectedAccount" class="button dropdown-toggle" @click="toggleMenu()"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="company-logo">
                <img :src="selectedAccount.logo" alt="" />
            </div>
            <h3 class="company-name">{{ reduceLarge(selectedAccount.name, 20) }}</h3>
        </button>
        <div class="dropdown-menu dropdown-menu-right" :class="{'show' : showMenu}" aria-labelledby="dropdownMenuButton">
            <ul>
                <li v-for="item in accounts">
                    <a href="javascript:void(0)" @click.prevent="switchToAnotherAccount(item.type, item)">
                        <div class="navigation-image">
                            <img :src="item.logo" alt="" />
                        </div>
                        <p class="navigation-text">{{ reduceLarge(item.name, 30) }}</p>
                    </a>
                </li>
                <li v-if="company">
                    <router-link  :to="{name : 'company.create'}">
                        <div class="navigation-icon">
                            <i class="eicon e-plus"></i>
                        </div>
                        <p class="navigation-text">{{ $t("New Company")}}</p>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name : 'my-account.information'}">
                        <div class="navigation-icon">
                            <i class="eicon e-setting"></i>
                        </div>
                        <p class="navigation-text">{{ $t("My Account")}}</p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/logout">
                        <div class="navigation-icon">
                            <i class="eicon e-logout"></i>
                        </div>
                        <p class="navigation-text">{{ $t("Logout")}}</p>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import {SWITCH_TO_ACCOUNT, COMPANY_ADDED, COMPANY_REMOVED, RESET_EXPIRED_PACKAGE} from '../../constants/action-type';
    import { SWITCHED_TO_ANOTHER_ACCOUNT } from '../../constants/events';
    import { EventBus } from '../../extra/event-bus';
    import client from '../../app/api/Client';

    export default {
        data() {
            return {
                showMenu : false,
                selectedMenu : null,
                dropdownMenu : []
            }
        },

        computed: {
            ...mapState(['user', 'company']),
            selectedAccount() {
                if (this.company === null) {
                    return {
                        "logo" : this.user.profile_image,
                        "name" : this.user.name
                    }
                } else {
                    return {
                        "logo" : this.company.logo,
                        "name" : this.company.name
                    }
                }

                return {
                    "logo" : "",
                    "name" : "name"
                }

            },
            accounts() {
                let companies = this.user.companies.map((c) => {
                    c.type = 'company';
                    return c;
                });
                if (this.company === null) {
                    return companies;
                } else {
                    companies = companies.filter(c => this.company.id !== c.id);
                    return companies;
                }
            }
        },
        methods : {
            ...mapActions([SWITCH_TO_ACCOUNT,COMPANY_ADDED,COMPANY_REMOVED, RESET_EXPIRED_PACKAGE]),
            async toggleMenu() {
                this.showMenu = !this.showMenu;
                if(this.showMenu) {
                    let {data:{data}} = await client().get('/user');
                    let oldCompanies = this.user.companies.map((c) => {
                        c.type = 'company';
                        return c.id;
                    });
                    let newCompanies = data.companies.map ((c) => {
                        c.type = 'company';
                        return c.id;
                    });
                    let companyAdded = _.difference(newCompanies, oldCompanies);
                    let companyToBeAdded = null;
                    if (companyAdded.length>0) {
                      companyAdded.forEach(cid => {
                          companyToBeAdded = data.companies.filter(c=> cid == c.id);
                            if(companyToBeAdded) {
                                this.COMPANY_ADDED(companyToBeAdded[0]);
                            }
                      });
                    }
                    let companyRemoved = _.difference(oldCompanies,newCompanies);
                    let companyToBeRemoved = null;
                    if (companyRemoved.length>0) {
                        companyRemoved.forEach(cid => {
                            companyToBeRemoved = this.user.companies.filter(c=> cid == c.id);
                            if(companyToBeRemoved) {
                                this.COMPANY_REMOVED(companyToBeRemoved[0]);
                                if(companyToBeRemoved[0].id == this.company.id) {
                                    this[SWITCH_TO_ACCOUNT](null);
                                }

                            }
                        });
                    }
                }

            },
            async switchToAnotherAccount(accountType, company) {
                try {
                    let companyId = null;
                    if (accountType === "user") {
                        this[SWITCH_TO_ACCOUNT](null);
                    } else {
                        companyId = company.id;
                        this[SWITCH_TO_ACCOUNT](company);
                    }

                    let {data} = await client().patch('/user/change-company', {company_id: companyId});

                    EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, accountType);

                    this[RESET_EXPIRED_PACKAGE](false);

                    this.showMenu = false;
                    this.$router.push({
                        name: 'dashboard'
                    })
                } catch (e) {
                }
            },
            hide(){
                this.showMenu = false;
            },
            reduceLarge(val, chars) {
                if(val.length > chars) {
                    return val.substring( 0 , chars)+'...';
                }
                return val;
            }
        },
        mounted () {
            this.popupItem = this.$el;
        },

    }
</script>

<style scoped>
    .profile-control .dropdown-menu ul {
        list-style: none;
    }
</style>