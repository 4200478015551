<template>
    <div class="ej-popup__wrap" :key="item.id" v-bind:class="{ 'hide-notification-box': hideReleaseNotification }" v-if="!isLoading && !isEmpty">
        <div class="ej-popup__bg"></div>
        <div class="ej-popup__body">
            <a href="javascript:void(0);" @click="closeNotification()" class="times"><i class="eicon e-close"></i></a>
            <h3>{{ item.title }}</h3>

            <vue-load-image v-if="item.src">
                <img slot="image" :src="item.src" alt=""/>
                <img slot="preloader" src="/assets/images/placeholder/loader.svg" alt=""/>
                <img slot="error" src="/assets/images/placeholder/error.svg" alt=""/>
            </vue-load-image>

            <p v-html="item.description"></p>

            <div class="buttons__block">
                <a :href="item.link" target="_blank" v-if="item.link && item.new_tab == 1" class="semi-button success-button mr-auto">{{ $t('View Details') }}</a>
                <a :href="item.link" v-else-if="item.link" class="semi-button success-button mr-auto">{{ $t('View Details') }}</a>

                <a href="javascript:void(0);" v-if="currentItem > 0" @click="prevItem()" class="semi-button semi-button-info ml-auto">{{ $t('Prev') }}</a>
                <a href="javascript:void(0);" v-if="currentItem !== totalItem" @click="nextItem()" class="semi-button info-button button-info ml-3" v-bind:class="{ 'ml-auto': currentItem===0 }">{{ $t('Next') }}</a>
                <a href="javascript:void(0);" v-else @click="closeNotification()" class="semi-button danger-button button-info ml-3">{{ $t('Finish') }}</a>
            </div>
            <span class="ej-popup__version">{{ $t('Version') }}: {{ release.version_no }}</span>
        </div>
    </div>
</template>

<script>
import client from "../../app/api/Client";
import {mapActions, mapState} from "vuex";
import {EventBus} from "../../extra/event-bus";
import {CHECK_RELEASE_NOTIFICATION} from "../../constants/events";
import {UPDATE_LAST_SEEN_VERSION} from "../../constants/action-type";
import VueLoadImage from "../_loaders/VueLoadImage";

import {isEmpty} from "lodash"


export default {
    data() {
        return {
            hideReleaseNotification: false,
            versionNo: '',
            isLoading: false,
            release: [],
            currentItem: 0,
            show: true
        }
    },

    components: {
        VueLoadImage
    },

    computed: {
        ...mapState(['company', 'user']),

        isEmpty() {
            return this.release.length === 0;
        },

        appVersion() {
            return process.env.MIX_SPA_VERSION;
        },

        localAppVersion() {
            return this.getLocalAppVersion();
        },

        items() {
            if(typeof this.release.items != 'undefined') {
                return this.release.items;
            }
            return [];
        },

        totalItem() {
            return this.items.length - 1;
        },

        item() {
            return this.items[this.currentItem];
        }
    },

    methods: {
        ...mapActions([UPDATE_LAST_SEEN_VERSION]),

        closeNotification() {
            this.hideReleaseNotification = true;
            this.setReleaseNotificationEvent(this.hideReleaseNotification);
            this.setLocalAppVersion(this.appVersion);
        },

        async setLocalAppVersion(appVersion) {
            let {data} = await client().post(`my-account/last-seen-version`, {'last_seen_version':appVersion});

            if(data.status === "SUCCESS") {
                this.UPDATE_LAST_SEEN_VERSION(appVersion);
            }
        },

        getLocalAppVersion() {
            return this.user.last_seen_version;
        },

        checkReleaseNotification() {
            this.hideReleaseNotification = this.appVersion === this.localAppVersion;
            this.setReleaseNotificationEvent(this.hideReleaseNotification);
        },

        async getReleaseNotification() {
            this.isLoading = true;
            try {
                let {data: {data}} = await client().get(`/release-notification/notify/${this.versionNo}`);
                this.release = data;

                if(isEmpty(data)) {
                    EventBus.$emit(CHECK_RELEASE_NOTIFICATION, true);
                }

                this.isLoading = false;
            } catch (error) {
            }
        },

        prevItem() {
            this.show = false;
            if(this.currentItem > 0) {
                this.currentItem--;
            }
            this.show = true;
        },

        nextItem() {
            this.show = false;
            if(this.currentItem < this.totalItem) {
                this.currentItem++;
            }
            this.show = true;
        },

        setReleaseNotificationEvent(value) {
            EventBus.$emit(CHECK_RELEASE_NOTIFICATION, value);
        }

    },

    created() {
        this.checkReleaseNotification();

        if (this.versionNo === '') {
            this.versionNo = this.appVersion;
        }

        if(this.user.type === 'candidate') {
            this.hideReleaseNotification = true;
            this.setReleaseNotificationEvent(this.hideReleaseNotification);
        }

        if(!this.hideReleaseNotification) {
            this.getReleaseNotification();
        }
    }
}
</script>

<style scoped>

    .ej-popup__wrap {
        position: relative;
    }
    .ej-popup__bg {
        background: rgba(0, 0, 0, .35);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
    }
    .ej-popup__wrap .ej-popup__body {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(100% + 16px);
        background: #fff;
        padding: 30px;
        width: 600px;
        border-radius: 10px;
        z-index: 99999;
        box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
        margin-left: -20px;
        max-height: 90vh;
        overflow-y: auto;
    }

    .ej-popup__body img {
        max-height: 400px;
    }

    .ej-popup__wrap .ej-popup__body::-webkit-scrollbar {
         width: 7px;
     }
    .ej-popup__wrap .ej-popup__body::-webkit-scrollbar-track {
         box-shadow: inset 0 0 6px rgba(125,128,145, 0.3);
         background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
     }
    .ej-popup__wrap .ej-popup__body::-webkit-scrollbar-thumb {
         border-radius: 3px;
         background: #597dfc;
     }
    @media all and (max-width: 767px) {
        .ej-popup__wrap .ej-popup__body {
            margin-top: 15px;
        }
    }
    .ej-popup__wrap .ej-popup__body .times {
        position: absolute;
        top: 28px;
        right: 15px;
        background: rgba(255, 95, 116, .1);
        padding: 5px;
        color: #ff5f74;
        font-size: 10px;
        line-height: 1;
        border-radius: 5px;
    }

    .ej-popup__wrap:before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-right: 16px solid #ffffff;
        z-index: 999999;
    }

    .ej-popup__wrap .ej-popup__body:before {
        position: absolute;
        top: 20px;
        left: -16px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-right: 16px solid #fff;
        content: '';
    }
    .ej-popup__wrap .ej-popup__body--reverse {
        left: auto;
        right: calc(100% + 16px);
    }
    .ej-popup__wrap .ej-popup__body--reverse:before {
        right: -16px;
        left: auto;
        border-right: unset;
        border-left: 16px solid #fff;
        content: '';
    }
    .ej-popup__wrap .ej-popup__body h3 {
        font-size: 16px;
        font-weight: 500;
        color: #1c2238;
        margin-bottom: 20px;
        padding-right: 25px;
    }
    .ej-popup__wrap .ej-popup__body ul li {
        color: #7d8091;
        font-size: 13px;
        list-style: disc;
    }
    .ej-popup__wrap .ej-popup__body ul li:not(:last-child) {
        margin-bottom: 6px;
    }
    .ej-popup__wrap .ej-popup__body img {
        margin-bottom: 20px;
        width: 100%;
    }
    .ej-popup__wrap .ej-popup__body p {
        font-size: 13px;
        font-weight: 400;
        color: #7d8091;
        margin-bottom: 20px;
    }
    .ej-popup__wrap .ej-popup__body .buttons__block {
        display: flex;
        flex-wrap: wrap;
    }
    .ej-popup__wrap .ej-popup__body .buttons__block a {
        margin-top: 10px;
    }
    .ej-popup__wrap .ej-popup__body .buttons__block a:not(:hover) {
        box-shadow: none;
    }
    @media all and (max-width: 1200px) {
        .ej-popup__wrap .ej-popup__body {
            width: 400px;
        }
        .ej-popup__wrap .ej-popup__body p {
            font-size: 13px;
        }
    }
    @media all and (max-width: 991px) {
        .ej-popup__wrap .ej-popup__body {
            width: 300px;
            padding: 25px;
        }
        .ej-popup__wrap .ej-popup__body .times {
            top: 23px;
        }
    }
    @media all and (max-width: 575px) {
        .ej-popup__wrap .ej-popup__body {
            width: 250px;
            padding: 15px;
        }
        .ej-popup__wrap .ej-popup__body .times {
            top: 13px;
        }
    }

    .hide-notification-box {
        display: none;
    }

    .ej-popup__version {
        margin-bottom: 0;
        font-size: 13px;
        color: #7d8091;
        font-weight: 400;
        border-radius: 3px;
        position: absolute;
        padding: 5px;
    }

</style>
