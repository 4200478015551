<template>
    <div class="dropdown notification-list"  v-on-click-away="hideNotification">
        <button @click="toggleNotification()" class="button light-button dropdown-toggle">
            <i class="eicon e-notification"></i>
            <span v-if="unreadNotifications > 0" class="notification-number">{{ unreadNotifications }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right show"
             v-if="showNotification">
            <div class="notification-head">
                <h4 style="display:inline;">{{ $t("Notifications")}}</h4>
                <div>
                    <a href="#" @click="clearAll()">{{ $t("Clear All")}}</a>
                </div>

            </div>
            <h6 class="empty-message text-center mt-0 mb-0" v-if="isEmpty">{{ $t("No notifications found.")}}</h6>

            <ul v-else>
                <li v-for="notif in notifications">
                    <a href="#" v-if="!notif.is_read" @click.prevent="clear(notif.id)" class="close-button">
                        <i class="eicon e-close"></i>
                    </a>
                    <a :href="notif.url">
                        <div class="notify-icon">
                            <i class="eicon e-notification"></i>
                        </div>
                        <p class="notify-details w-100">
                            <span class="message-title">{{$t(notif.title, notif.keys) }}</span>
                            <small class="message-text" v-html="$t(notif.message, notif.keys)"></small>
                            <small class="text-time">{{ notif.posted_at }}</small>
                        </p>
                    </a>
                </li>
            </ul>
            <div class="notification-footer text-center">
                <router-link :to="{ name: 'notification'}" class="semi-button semi-button-info mb-2">
                    {{ $t("View all")}}
                </router-link>
                <a class="semi-button semi-button-info" href="javascript:void(0);" @click="managerNotificationSettings()" v-if="this.company">
                    {{ $t("Notification Settings")}}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapActions, mapState} from 'vuex';
    import {LOAD_NOTIFICATION} from "../../constants/action-type";
    import {NOTIFICATION_CLEARED, SWITCHED_TO_ANOTHER_ACCOUNT, SHOW_NOTIFICATION_SETTINGS} from "../../constants/events";
    import { EventBus } from '../../extra/event-bus';
    import client from "../../app/api/Client";

    export default {
        data() {
            return {
                showNotification: false,
                limit: 5,
                unread: 0,
                eventFired : false
            }
        },
        methods: {
            ...mapActions([LOAD_NOTIFICATION]),
            toggleNotification() {
                this.showNotification = !this.showNotification;
            },
            hideNotification() {
                this.showNotification = false;
            },
            async getNotifications() {
                try {
                    let {data: {data}} = await client().get('user/notification', {
                        params: {
                            limit: this.limit,
                            status: 'unseen'
                        }
                    });

                    let notification = data.notifications;
                    this.unread = data.count;
                    this[LOAD_NOTIFICATION](notification);
                } catch (error) {
                }
            },
            async clearAll() {
                try {
                    if (this.unread > 0) {
                        let {data: {data, message}} = await client().post(`user/notification/clear-all`);
                        this.$toast.success(this.$t(message));
                        await this.getNotifications();
                        EventBus.$emit(NOTIFICATION_CLEARED, this.unread);
                    }
                    this.hideNotification();
                } catch (error) {
                }
            },
            async clear(notification) {
                try {
                    let {data: {message}} = await client().get(`user/notification/${notification}/clear`);
                    this.$toast.success(this.$t(message));
                    await this.getNotifications();
                    this.hideNotification();
                    EventBus.$emit(NOTIFICATION_CLEARED, this.unread);
                } catch (error) {
                }
            },
            managerNotificationSettings() {
                this.$router.push({name: 'company.setting.notifications'});
                EventBus.$emit(SHOW_NOTIFICATION_SETTINGS);
            },
        },
        computed: {
            ...mapState(['notifications','company']),
            unreadNotifications() {
                return this.unread;
            },
            isEmpty() {
                return this.notifications.length === 0;
            }
        },
        beforeMount() {
            EventBus.$on(SWITCHED_TO_ANOTHER_ACCOUNT, (accountType) => {
                if (accountType === 'company') {
                  this.getNotifications();
                }
            });
        },
        async created() {
            this.popupItem = this.$el;
            await this.getNotifications();
        },
        beforeDestroy() {
            EventBus.$off(SWITCHED_TO_ANOTHER_ACCOUNT);
        }
    }
</script>

<style scoped>
.notification-head a {
    font-size: .8rem !important;
}

.notification-list .dropdown-menu {
    top: calc(100% + 25px) !important;
}
.notification-list .dropdown-menu ul {
    list-style: none;
}
</style>
